import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import BigProjects from '../components/bigProjects';
import LittleProjects from '../components/littleProjects'
import Resume from '../components/resume';
import Art from '../components/art'

function Home() {
  const [expandedBoxId, setExpandedBoxId] = useState(null); // Track the expanded box
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State for window width

  const boxes = [
    { id: 1, color: '#C5EB73', descr: 'BIG PROJECTS' },
    { id: 2, color: '#90A955', descr: 'LITTLE PROJECTS' },
    { id: 3, color: '#4F772D', descr: 'RESUME' },
    { id: 4, color: '#31572C', descr: 'ART' },
  ];

  const handleBoxClick = (id) => {
    setExpandedBoxId(expandedBoxId === id ? null : id); // Toggle expand/collapse
  };

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box w="100%" h='100vh' bg="#F0EBE7" position="relative" overflowX='scroll'>
      <Flex
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        p="6%"
        flexDirection="column"
      >
        <Text
          fontFamily="Evagir"
          fontSize="120px"
          textColor="#644E49"
          w="100%"
        >
          ELLa HagEN
        </Text>
        <Text>
          I’m a third-year computer science student located at CalPoly
          University, SLO interested in <br /> software development!
        </Text>
        <Divider borderWidth="1px" borderColor="#644E49" my="20px" />
        <Box display="flex" flexDirection="column" gap="20px"  w='100%' userSelect='none'>
          {boxes.map((box, index) => (
            <motion.div
              key={box.id}
              initial={{ y: window.innerHeight }}
              animate={{ y: 0 }}
              transition={{
                duration: 1,
                ease: 'easeOut',
                delay: index * 0.5,
              }}
              whileHover={{
                x: 15,
                transition: { ease: "easeInOut", duration: 0.3 },
              }}
              onClick={() => box.id === expandedBoxId || expandedBoxId === null ? handleBoxClick(box.id) : setExpandedBoxId(null)} // Handle click event
            >
              <Box position="relative" p="20px" display="inline-block"
                visibility={expandedBoxId === null || expandedBoxId === box.id ? 'visible' : 'hidden'}                   onClick={() => console.log("help")}
                >
                <Box
                  right={0}
                  bottom={0}
                  bg={box.color}
                  filter="blur(8px)"
                  zIndex={-1}
                  transition="opacity 1s ease, transform 1s ease"
                  transform={expandedBoxId === box.id ? 'scale(100)' : 'scale(1)'}
                  position={expandedBoxId === box.id ? 'fixed' : 'absolute'}
                  top={expandedBoxId === box.id ? '0' : '0'}
                  left={expandedBoxId === box.id ? '0' : '0'}
                  width={expandedBoxId === box.id ? '100vw' : 'auto'}
                  height={expandedBoxId === box.id ? '100vh' : 'auto'}
                  opacity={expandedBoxId === box.id ? 1 : 0.3}
                  onClick={() => console.log("help")}
                />
                <Text fontWeight='bold' fontSize='20px'
                  color={expandedBoxId=== box.id ? "white" : "#9C9187"} w='100%' h='100%'
                  opacity={1} _hover={{ textColor: '#644E49' }} userSelect='none'
                  transition="transform 0.5s ease-in-out"
                  transform={expandedBoxId === box.id ? 'scale(1.5)' : 'scale(1)'}>
                  {box.descr}
                  {expandedBoxId !== null ?
                    <Text fontSize='10px'>click anywhere to exit</Text>
                    : <></>}
                </Text>
              </Box>
            </motion.div>
          ))}
        </Box>
      </Flex>
      {expandedBoxId !== null ?
        <motion.div
          animate={{ x: [0, 5, 0] }}
          transition={{ duration: 1, ease: 'easeInOut', repeat: Infinity }}
          drag="x"
          dragConstraints={{ right: 0, left: -windowWidth * 1.1 }} // Use windowWidth state
          style={{ position: 'fixed', top: 0, left: '80%', width: '100vw', height: '100vh' }}

        >
          <Box position='absolute' bg='#F0EBE7' borderRadius='50%'
            h='100vh' w='100vh' opacity='1' left='0%'
            boxShadow='xl'>
            <Text position='absolute' top='50%' left='3%' fontWeight='bold' textColor="#9E9891">
              &lt;&lt;  PULL
            </Text>
          </Box>
          <Box position='absolute' left='30%'>
            {expandedBoxId === 1 ? <BigProjects /> : <></>}
            {expandedBoxId === 2 ? <LittleProjects/> : <></>}
            {expandedBoxId === 3 ? <Resume/> : <></>}
            {expandedBoxId === 4 ? <Art/> : <></>}

          </Box>

        </motion.div> : <></>}
    </Box>
  );
}

export default Home;
