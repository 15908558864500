import * as React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import Home from './pages/Home.js'
import Bendy from './pages/bendy.js';
import Guy from './pages/guy.js';
import NoFace from './pages/noFace.js';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={ <Home/>}></Route>
          <Route path="/bendy" element={ <Bendy/>}></Route>
          <Route path="/guy" element={ <Guy/>}></Route>
          <Route path="/noface" element={ <NoFace/>}></Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
