import React, { useState, useEffect, useRef } from 'react';
import {Box} from '@chakra-ui/react'

function Guy(){
  const [mousePos, setMousePos] = useState({ x: 100, y: 50 });
  const [circlePos, setCirclePos] = useState({ x: 100, y: 50 });
  const animationRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const updateCirclePosition = () => {
      const { x: mouseX, y: mouseY } = mousePos;
      const { x: circleX, y: circleY } = circlePos;

      const dx = (mouseX - circleX) * 0.03; 
      const dy = (mouseY - circleY) * 0.03;
 
      setCirclePos({ x: circleX + dx, y: circleY + dy });
      

      animationRef.current = requestAnimationFrame(updateCirclePosition);
    };

    animationRef.current = requestAnimationFrame(updateCirclePosition);

    return () => cancelAnimationFrame(animationRef.current);
  }, [mousePos, circlePos]);

  const angle = Math.atan2(mousePos.y - circlePos.y, mousePos.x - circlePos.x);
  const radius = 25; 
  const eyeDistance = radius; 

  const smallCircle1X = circlePos.x + Math.cos(angle - Math.PI / 6) * eyeDistance; // Left eye
  const smallCircle1Y = circlePos.y + Math.sin(angle - Math.PI / 6) * eyeDistance;
  const smallCircle2X = circlePos.x + Math.cos(angle + Math.PI / 6) * eyeDistance; // Right eye
  const smallCircle2Y = circlePos.y + Math.sin(angle + Math.PI / 6) * eyeDistance;


  return (
    <Box position='fixed' left='0px' top='0px' width='100vw' height='100vh' bg='#A1BEED'>
      {/*Mouth*/}
      <Box position='fixed' left={`${circlePos.x -40}px`} top={`${circlePos.y - 25}px`}
      width='50px' height='50px' borderRadius='50%' bg='#93A9CC' pointerEvents='none'
      />
      <Box position='fixed' left={`${circlePos.x - 25}px`} top={`${circlePos.y - 25}px`}
      width='50px' height='50px' borderRadius='50%' bg='#D4B37B' pointerEvents='none'
      />
      {/*Fish Eye 2*/}
      <Box position='fixed' left={`${smallCircle1X - 2.5}px`} top={`${smallCircle1Y - 2.5}px`}
        width='5px' height='5px' borderRadius='50%' bg='#5E5442' pointerEvents='none'
      />
      {/* Fish Eye 2 */}
      <Box position='fixed' left={`${smallCircle2X - 2.5}px`} top={`${smallCircle2Y - 2.5}px`}
        width='5px' height='5px' borderRadius='50%' bg='#5E5442' pointerEvents='none'
      />
    </Box>
  );
};

export default Guy;
