import { Box, Divider, Image, Link, Text, Flex } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useState, useEffect, useRef } from "react";

function Resume() {
  return (
    <Box position="absolute" display="flex" flexDirection="column" left="110%" w="100vw" h="100vh" bg="#F0EBE7"
        p='5vh' fontFamily='Times New Roman' overflow='scroll'>
      <Box w='100%'>
        <Text textAlign='center' fontWeight='black' textColor='#644E49' fontSize='35px'>Ella Hagen</Text>
        <Text textAlign='center' fontWeight='bold' textColor='#9E9891' fontSize='20px'>San Luis Obispo, CA · ella.kl.hagen@gmail.com</Text>
        <Link href='https://www.linkedin.com/in/ella-hagen-133152296' isExternal><Text textAlign='center' fontWeight='bold' textColor='#9E9891' fontSize='20px'>www.linkedin.com/in/ella-hagen-133152296</Text></Link>
      </Box>
      <Box w='100%' mt='50px'>
        <Text>EDUCATION</Text>
        <Divider w='100%' borderWidth="1px" borderColor="#A39591"/>
        <Flex m='10px 0px 0px 20px' w='90%' justifyContent='space-between'>
            <Text fontWeight='bold'>CalPoly University</Text>
            <Text>San Luis Obispo, CA</Text>
        </Flex>
        <Flex ml='20px' w='90%' justifyContent='space-between'>
            <Text>BS Computer Science, GPA: 3.75</Text>
            <Text>September 2022 - June 2026</Text>
        </Flex>
      </Box>
      <Box w='100%' mt='50px'>
        <Text>WORK EXPERIENCE</Text>
        <Divider w='100%' borderWidth="1px" borderColor="#A39591"/>
        <Flex m='10px 0px 0px 20px' w='90%' justifyContent='space-between'>
            <Text fontWeight='bold'>Hack4Impact</Text>
            <Text>San Luis Obispo, CA</Text>
        </Flex>
        <Flex ml='20px' w='90%' justifyContent='space-between'>
            <Text fontStyle='italic'>Technical Lead</Text>
            <Text>October 2024 - Present</Text>
        </Flex>
        <Text ml='40px'>
            - Recently appointed as Tech Lead for a web development project aimed at building applications for a nonprofit organization using React, JavaScript, and Node.js. <br />
            - Will mentor developers and conduct code reviews to ensure best practices within the team. <br />
            - Will work as a liaison between the developers, designers, and product manager to ensure a product that satisfies the client.
        </Text>
        <Flex m='10px 0px 0px 20px' w='90%' justifyContent='space-between'>
            <Text fontWeight='bold'>CalPoly University</Text>
            <Text>San Luis Obispo, CA</Text>
        </Flex>
        <Flex ml='20px' w='90%' justifyContent='space-between'>
            <Text fontStyle='italic'>Undergraduate Software Engineer</Text>
            <Text>June 2024 - September 2024</Text>
        </Flex>
        <Text ml='40px'>
            - Developed a program that analyzed student scehdules to identify the optimal office hour time maximizing the availability of unique students<br />
            - Developed a browser automated program using Selenium to scrape class and student information from the instructor portal. <br />
            - Designed a user-friendly interface using React, HTML/CSS and JavaScript, allowing the professor to easily input data and visualize the results.
        </Text>
        <Flex m='10px 0px 0px 20px' w='90%' justifyContent='space-between'>
            <Text fontWeight='bold'>Hack4Impact</Text>
            <Text>San Luis Obispo, CA</Text>
        </Flex>
        <Flex ml='20px' w='90%' justifyContent='space-between'>
            <Text fontStyle='italic'>Software Developer</Text>
            <Text>October 2023 - June 2024</Text>
        </Flex>
        <Text ml='40px'>
            - Collaborated with a team in Hack4Impact, a software development club to create software for nonprofit organizations. <br/>
            - Designed and implemented both frontend and backend components for key features, including: the login
              page, waiver management, and the admin event dashboard. <br/>
            - Delivered a functional solution that streamlined operations and improved user engagement for the
nonprofit.
        </Text>
      </Box>
      <Box w='100%' mt='50px'>
        <Text>PROJECTS</Text>
        <Divider w='100%' borderWidth="1px" borderColor="#A39591"/>
        <Flex m='10px 0px 0px 20px' w='90%' justifyContent='space-between'>
            <Text fontWeight='bold'>STUDYWELL</Text>
        </Flex>
        <Flex ml='20px' w='90%' justifyContent='space-between'>
            <Text fontStyle='italic'>React.js, JavaScript</Text>
        </Flex>
        <Text ml='40px'>
            - Software which implements the pomodoro studying technique and a task manager, complete with frontend
            and backend functionality. <br/>
            - Implemented the frontend for the login and task dashboard along with the backend for a user’s personal
            tasks.
        </Text>
        <Flex m='10px 0px 0px 20px' w='90%' justifyContent='space-between'>
            <Text fontWeight='bold'>Harmony API</Text>
        </Flex>
        <Flex ml='20px' w='90%' justifyContent='space-between'>
            <Text fontStyle='italic'>SQL, Python</Text>
        </Flex>
        <Text ml='40px'>
            - A music API which recommends songs and artists according to the listeners past choices and playlists. <br/>
            - Implemented the algorithm that fetches music based on their preferred genres along with an algorithm which
            allows the users to find new artists and songs.
        </Text>
      </Box>
      <Box w='100%' mt='50px'>
        <Text>SKILLS</Text>
        <Divider w='100%' borderWidth="1px" borderColor="#A39591"/>
        <Text fontWeight='bold' m='10px 0px 0px 20px'>Programming Languages: <Box as='span' fontWeight='normal'> Python, JavaScript, Java, SQL, TypeScript, HTML, CSS</Box></Text>
        <Text fontWeight='bold' ml='20px'>Libraries: <Box as='span' fontWeight='normal'> React.Js, Next.js, Node.js, Selenium, Chakra UI, Framer Motion, Jest</Box></Text>
        <Text fontWeight='bold' ml='20px'>Methodologies: <Box as='span' fontWeight='normal'>Agile, SCRUM</Box></Text>
      </Box>
    </Box>
  );
}

export default Resume;
