import { Box, Grid, GridItem, Text, Image} from '@chakra-ui/react';
import { useState } from 'react';

function Art() {
    const [visibilityArr, setVisibilityArr] = useState([false, false, false, false, false, false, false])

    return (
    <Box
      position="absolute" display="flex" flexDirection="row" left="110%" w="100vw"
      height="100vh" bg="#F0EBE7" p={6} overflow='scroll'> 
        <Grid w='100%' h='100%'
            templateColumns="repeat(10, 1fr)" 
            templateRows='45vh 35vh 30vh 45vh 50vh 30vh' 
            gap={6} 
            >
            <GridItem colSpan={{base: 10, md: 4}} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden' position='relative'
            visibility={visibilityArr[0] ? 'visiblile' : 'hidden'}>
                <Image id='0' src='/art/rope.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[0] = true;
                    return newArr;
                })}
                />
            </GridItem>
            <GridItem colSpan={{base: 10, md: 3}} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            textColor='#644E49' fontWeight='black' textAlign='center' p='50px' display='flex' flexDirection='column' justifyContent='center'>
            <Text fontSize='5vh'>my art</Text>
                <Text fontSize='2vh'>a small and growing collection</Text>
                <Text fontWeight='black' textColor="#9E9891" fontSize='14px' mt='10px'>&gt;&gt; PULL TO THE RIGHT TO RETURN</Text>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 3}} rowSpan={2} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            visibility={visibilityArr[1] ? 'visiblile' : 'hidden'}>
                <Image id='1' src='/art/cloth.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[1] = true; 
                    return newArr;
                })}/>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 7}} rowSpan={2} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            visibility={visibilityArr[2] ? 'visiblile' : 'hidden'}>
                <Image id='2' src='/art/look.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[2] = true; 
                    return newArr;
                })}/>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 3}} rowSpan={2} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            visibility={visibilityArr[3] ? 'visiblile' : 'hidden'}>
                <Image id='3' src='/art/ripped.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[3] = true; 
                    return newArr;
                })}/>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 3}}  bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            visibility={visibilityArr[4] ? 'visiblile' : 'hidden'}>
                <Image id='4' src='/art/statue.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[4] = true; 
                    return newArr;
                })}/>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 4}}  bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            visibility={visibilityArr[5] ? 'visiblile' : 'hidden'}>
                <Image id='5' src='/art/rip.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[5] = true; 
                    return newArr;
                })}/>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 10}} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'
            visibility={visibilityArr[6] ? 'visiblile' : 'hidden'}>
                <Image id='6' src='/art/split.png' objectFit='cover' w='100%' h='100%' draggable='false'
                onLoad={() => setVisibilityArr(prev => {
                    const newArr = [...prev];
                    newArr[6] = true; 
                    return newArr;
                })}/>
            </GridItem> 
        </Grid>
    </Box>
  );
}

export default Art;
