import { Box} from "@chakra-ui/react"
import {useEffect, useState} from 'react'
import { keyframes } from "@emotion/react";
function Handle({l}){
    const swing = keyframes`
        0% { transform: rotate(0deg); } 
        25% { transform: rotate(5deg); } 
        50% {transform : rotate(0deg);}
        75% { transform: rotate(-5deg); } 
        100% { transform: rotate(0deg); } 
    `;
    return(
        <Box position='absolute' left={`${l}px`} top='0px' animation={`${swing} 5s infinite linear`}
            transformOrigin='top center'>
                <Box w='10vw' h='10vw' top='20px'left='3px' position='absolute' borderRadius='35px' border='7px solid #102F17'/>
                <Box  w='10vw' h='10vw' top='20px'  position = 'absolute' borderRadius='35px' border='7px solid #696B61'/>
                <Box position='absolute' top='-32px' left='16px' w='20px' h='65px' bg='#414127'
                borderWidth='0px 3px 10px 2px' borderColor='#0C1C16' borderRadius='0px 0px 3px 3px'/>
                <Box position='absolute' left='26px' w='1.2vw' h='1vh' bg='#303330' borderRadius='2px'
                border='1px solid #0D1816'/>
        </Box>
    )
}

function NoFace(){

    return(
        <Box position='relative' w='100vw' h='100vh' overflow='hidden'>
            <Box position='relative' w='100vw' h='100vh' bg='green' overflow='hidden'>
                <Box display='flex' w='100%' h='45%' bgGradient='linear(to-b, #2B1F17 20%, #6B6752 30%, #383F1B, #2B1F17 65%)'  gap='5%'>
                    <Box w='2.5%' h='95%' bg='#17170E' p='1% 1% 1% 0%'  borderColor='#464731' borderWidth={2} borderLeft='none'>
                        <Box w='100%' h='100%' bgGradient='linear(to-b, #6EC0EB, #9ADBF5 65%, #2AA4BA 70%, #39CBC6)'></Box>`    `
                    </Box>
                    <Box w='25%' h='95%' bg='#1F170E' p='1%' borderColor='#464731' borderWidth={2}>
                        <Box w='100%' h='100%' bgGradient='linear(to-b, #6EC0EB, #9ADBF5 65%, #2AA4BA 70%, #39CBC6)'></Box>
                    </Box>
                    <Box w='25%' h='95%' bg='#1F170E' p='1%' borderColor='#464731' borderWidth={2}>
                        <Box w='100%' h='100%' bgGradient='linear(to-b, #6EC0EB, #9ADBF5 65%, #2AA4BA 70%, #39CBC6)'></Box>

                    </Box>
                    <Box w='25%' h='95%' bg='#1F170E' p='1%' borderColor='#464731' borderWidth={2}>
                        <Box w='100%' h='100%' bgGradient='linear(to-b, #6EC0EB, #9ADBF5 65%, #2AA4BA 70%, #39CBC6)'></Box>
                    </Box>
                    <Box w='2.5%' h='95%' bg='#17170E' p='1% 0% 1% 1%'  borderColor='#464731' borderWidth={2} borderRight='none'>
                        <Box w='100%' h='100%' bgGradient='linear(to-b, #6EC0EB, #9ADBF5 65%, #2AA4BA 70%, #39CBC6)'></Box>
                    </Box>
                </Box>
                <Box w='100%' h='20%' bgGradient='linear(to-b, #771525, 1%, #6F071D 2%, #450712 80%, #34080B, #140308)'>
                </Box>
                <Box w='100%' h='10%' bgGradient='linear(to-b, #34080B 90%, #6F071D)'>
                </Box>
                <Box w='100%' h='7.5%' bgGradient='linear(to-b, #6F071D, #450712, #310A11, #20070A)'>
                </Box>
                <Box w='100%' h='17.5%' bg='#07080A'>
                </Box>
                {window.innerWidth < 500 ? Array.from({length : Math.floor(window.innerWidth / 200) + 1}, (x, index) => (
                    <Handle key={index} l={index * 200}/>
                )) : <></>}
            </Box>
            <Box>
            <Box position='absolute' w='auto' h='37.5vh' left='57vw' top='45vh' overflow='hidden'>
                <svg w='auto' h='100%' viewBox="0 0 496 1120" style={{ position: 'absolute', top: '-31vh', left: 0 }}>
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="30%" style={{ stopColor: 'transparent', stopOpacity: 1 }} />
                            <stop offset="70%" style={{ stopColor: 'rgba(0, 0, 0, 0.3)', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                    <path
                        d="M0.999937 660.5C4.19994 744.1 9.99994 769.667 12.4999 772V830C12.4999 876.4 4.83327 1003.67 0.999937 1061.5C19.6666 1072.67 86.7999 1099 206 1115C325.2 1131 423.667 1095 458 1075C464.667 1019.33 477.5 895.1 475.5 843.5C473.5 791.9 495.5 786.5 495.5 756.5C462 438 431 511.5 395.5 340C360 168.5 371 -14 221.5 1.5C72 17 63.4999 337.5 56.9999 360C50.4999 382.5 -3.00006 556 0.999937 660.5Z"
                        fill="url(#grad1)" 
                        stroke="none" 
                    />
                </svg>
            </Box>
            </Box>
            <Box position='absolute' h='80vh' left='55vw' top='17vh'>
                <svg  height="100%" viewBox="0 0 496 1120" style={{filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 1))'}}>
                    <defs>
                    <radialGradient id="grad2" cx="45%" cy="30%" r="70%">
                        <stop offset="0%" style={{ stopColor: 'black', stopOpacity: 1 }} />
                        <stop offset="30%" style={{ stopColor: 'black', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: 'gray', stopOpacity: 0.1 }} />
                    </radialGradient>
                    </defs>
                    <path 
                    d="M0.999937 660.5C4.19994 744.1 9.99994 769.667 12.4999 772V830C12.4999 876.4 4.83327 1003.67 0.999937 1061.5C19.6666 1072.67 86.7999 1099 206 1115C325.2 1131 423.667 1095 458 1075C464.667 1019.33 477.5 895.1 475.5 843.5C473.5 791.9 495.5 786.5 495.5 756.5C462 438 431 511.5 395.5 340C360 168.5 371 -14 221.5 1.5C72 17 63.4999 337.5 56.9999 360C50.4999 382.5 -3.00006 556 0.999937 660.5Z" 
                    fill="url(#grad2)" 
                    stroke="none" 
                    />
                </svg>
                <Box position='absolute' top='2%' left='27%' w='100%' h='100%'>
                    <Box w='100%' h='100%'>
                    <svg height="30%" viewBox="0 0 205 351">
                        <path 
                            fill='#B7B4A3'
                            d="M28 289C20 271 6.33333 202.167 0.5 170V127.5C0.5 103.5 11 69 18.5 52.5C26 36 44 11.5 85.5 3.49999C127 -4.50001 140 9.49999 159.5 19C179 28.5 197 66.5 202.5 99C208 131.5 200.5 176.5 197 226C193.5 275.5 160 332.5 151 341C142 349.5 87.5 353.5 67 346.5C46.5 339.5 38 311.5 28 289Z"
                        />
                    </svg>
                    </Box>
                    <Box position='absolute' top='5%' right='43%' w='100%' h='100%'>
                        <svg height="6%" width="100%" viewBox="0 0 32 61">
                            <path 
                                fill='#696268' 
                                d="M23 0.5C8.6 0.9 2.33333 35 1 52C14.6 66.8 26 58.1667 30 52C31.1667 42.3333 31.4 18.5 23 0.5Z"
                            />
                        </svg>
                    </Box>
                    <Box position='absolute' top='12%' left='2%' height='2.5%' w='10%' bg='#161313' borderRadius='50%'/>
                    <Box position='absolute' top='15%' right='43%' w='100%' h='100%'>
                        <svg height="1%" width="100%" viewBox="0 0 39 12">
                            <path 
                                fill='#6A695D' 
                                d="M37.4997 2.0001C33.9999 1.00004 14.5 1.00006 4 2.00006C-0.5 2.00006 -0.500032 7.00014 5.49973 9.5001C10.5 11.5836 21.4997 11.5001 29.4997 9.50009C37.4997 7.50009 39.9035 2.68697 37.4997 2.0001Z"
                            />
                        </svg>
                    </Box>
                    <Box position='absolute' top='16.5%' right='42%' w='100%' h='100%'>
                        <svg height="8%" width="100%" viewBox="0 0 30 88">
                            <path 
                                fill='#696268' 
                                d="M8.50008 1.00002C0.500079 0.200018 0.500079 8.33335 1.50008 12.5C1.83339 15.5 2.9 25 4.5 39C6.5 56.5 25 96 27 85.5C29 75 29 26 29 12.5C29 -0.999964 18.5001 2.00002 8.50008 1.00002Z"
                            />
                        </svg>
                    </Box>
                    <Box position='absolute' top='5%' right='17%' w='100%' h='100%'>
                        <svg height="6%" width="100%" viewBox="0 0 32 61">
                            <path 
                                fill='#696268' 
                                d="M0.5 1.5V54.5C8 60.5 21.5 62 29 48.5C27 12.1 9.16667 2 0.5 1.5Z"
                            />
                        </svg>
                    </Box>
                    <Box position='absolute' top='12%' left='27%' height='2.5%' w='10%' bg='#161313' borderRadius='50%'/>
                    <Box position='absolute' top='15%' right='18%' w='100%' h='100%'>
                        <svg height="1%" width="100%" viewBox="0 0 39 12">
                            <path 
                                fill='#6A695D' 
                                d="M37.4997 2.0001C33.9999 1.00004 14.5 1.00006 4 2.00006C-0.5 2.00006 -0.500032 7.00014 5.49973 9.5001C10.5 11.5836 21.4997 11.5001 29.4997 9.50009C37.4997 7.50009 39.9035 2.68697 37.4997 2.0001Z"
                            />
                        </svg>
                    </Box>
                    <Box position='absolute' top='16.5%' right='18%' w='100%' h='100%'>
                        <svg height="8%" width="100%" viewBox="0 0 30 88">
                            <path 
                                fill='#696268' 
                                d="M5 5.5C-0.599995 6.7 0.333338 49 1.5 70C1.5 74.8333 2.5 84.2 6.5 83C11.5 81.5 14 74 22 52C30 30 31 11 29.5 5.5C28 0 12 0 5 5.5Z"
                            />
                        </svg>
                    </Box>
                    <Box position='absolute' top='25%' left='13%' height='2%' w='13%' bg='#161313' borderRadius='50%'/>
                    <Box position='absolute' top='27.5%' left='15%' height='0.75%' w='9%' bg='#65625A' borderRadius='50%'/>
                    <Box position='absolute' top='1%' right='22%' w='100%' h='100%'>
                    <svg height="29%" width="100%" viewBox="0 0 124 342">
                        <path 
                            fill='rgba(0, 0, 0, 0.5)' 
                            d="M66 311.5C58.8 327.1 19.6667 337.667 1 341H27.5C43 341 66 336 77.5 327.5C89 319 105.5 277 110.5 257.5C115.5 238 123 177 123 137C123 97 119.5 60 110.5 41.5C103.3 26.7 77.8333 8 66 0.5C69 3.66667 77.9 16.3 89.5 41.5C104 73 92.5 144.5 93.5 196.5C94.5 248.5 75 292 66 311.5Z"
                        />
                    </svg>
                    </Box> 
                </Box>
            </Box>
           
        </Box>
    )

}
export default NoFace