import { Box, Grid, GridItem, Text, Image, Link } from '@chakra-ui/react';

function LittleProjects() {
  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="row"
      left="110%"
      w="100vw"
      height="100vh"
      bg="#F0EBE7"
      p={6}
      overflow='scroll'
    > 
        <Grid w='100%'
            templateRows={{base: '40vh 40vh 45vh 0vh 20vh 30vh', md: '40vh 20vh auto'}}
            templateColumns='repeat(10, 1fr)'
            gap={6}
            >
            <GridItem colSpan={{base: 10, md: 3}} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'>
              <Box position='relative' w='100%' h='100%' overflow='hidden'>
                <Link href='https://delightful-mushroom-0180dbf1e.4.azurestaticapps.net' isExternal>
                <Image src='/smallProjects/Studywell.png' objectFit='cover' w='100%' h='100%' draggable='false'/>
                </Link>
              </Box>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 3}} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'>
              <Box position='relative' w='100%' h='100%' overflow='hidden'>
                <iframe src='/noFace' title="bendy" style={{width:'100%', height:'100%'}}/>
              </Box>
            </GridItem>
            <GridItem rowSpan={2} colSpan={{base: 10, md: 4}} boxShadow='base' borderRadius='30px' overflow='hidden'>
              <Box position='relative' w='100%' h='100%' overflow='hidden'>
                <iframe src='/bendy' title="bendy" style={{width:'100%', height:'100%'}}/>
              </Box>
            </GridItem>
            <GridItem colSpan={{base: 10, md: 6}} textColor='#644E49' fontWeight='black' textAlign='center' display='flex'
            justifyContent='center' alignItems='center' flexDirection='column'>
                <Text fontSize='5vh'>little projects</Text>
                <Text fontSize='2vh'>tiny little things just for fun.</Text>
                <Text fontWeight='black' textColor="#9E9891" fontSize='14px' mt='10px'>&gt;&gt; PULL TO THE RIGHT TO RETURN</Text>
            </GridItem>
    
            <GridItem colSpan={10} bg='papayawhip' boxShadow='base' borderRadius='30px' overflow='hidden'>
              <Box position='relative' w='100%' h='100%' overflow='hidden'>
                <iframe src='/guy' title="bendy" style={{width:'100%', height:'100%'}}/>
              </Box>
            </GridItem>

            
        </Grid>
    </Box>
  );
}

export default LittleProjects;
