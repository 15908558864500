import { Box, Image, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useState, useEffect, useRef } from "react";

function BigProjects() {
  const [curProject, setCurProject] = useState(0);
  const [isWide, setIsWide] = useState(window.innerWidth > 900)
  const projects = [
    {
      tags: ["React", "Selenium", "Python", "JavaScript", "Chakra UI"],
      projectName: "Professor Scheduling",
      descr:
        "Problem: CalPoly professors currently lack access to data on how many unique students can attend their office hours, leading them to schedule without sufficient information. \nThis project provides professors with recommended times that maximize unique student availability, while also allowing for custom scheduling. Since Cal Poly does not offer an accessible API for professors, the software uses Selenium to manually retrieve student and class information from the instructor portal.",
      images: [
        "/schedulingImages/imageOne.png",
        "/schedulingImages/imageTwo.png",
        "/schedulingImages/imageThree.png",
        "/schedulingImages/imageFour.png",
        "/schedulingImages/imageFive.png",
      ],
    },
    {
      tags: ["React", "JavaScript", "Node.js", "Mongoose", "MongoDB", "ChakraUI"],
      projectName: "Beaver Brigade Portal",
      descr:
        "Problem: The Beaver Brigade, a nonprofit organization in San Luis Obispo focused on environmental conservation, previously managed volunteer information and signups manually, which led to significant administrative overhead.\n This project, initiated by Hack4Impact, aimed to develop software that would automate the volunteer process for Beaver Brigade employees. My contributions included implementing frontend and backend for features such as the digital waiver, signup process, admin interface, and the event editing page.",
      images: [
        "/beaverImages/imageOne.png",
        "/beaverImages/imageTwo.png",
        "/beaverImages/imageThree.png",
        "/beaverImages/imageFour.png",
        "/beaverImages/imageFive.png",
      ],
    },
  ];

  const scrollContainerRef = useRef(null);
  const scrollContentRef = useRef(null);

  const onRightClick = () => {
    setCurProject((curProject + 1) % projects.length);
  };

  const onLeftClick = () => {
    setCurProject((curProject - 1 + projects.length) % projects.length);
  };

  useEffect(() => {
    const scrollContent = scrollContentRef.current;
    let scrollPosition = 0;
    function scrollContentLoop() {
      if (scrollContent) {
        scrollPosition += 1;
        if (scrollPosition >= scrollContent.scrollHeight - window.innerHeight) {
            scrollPosition = 0;
        }
        scrollContent.style.transform = `translateY(-${scrollPosition}px)`;
        requestAnimationFrame(scrollContentLoop);
    }}
    scrollContentLoop();
    return () => {
      cancelAnimationFrame(scrollContentLoop);
    };
  }, [curProject]);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 900);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box position="absolute" display="flex" flexDirection="row" left="110%" w="100vw" h="100vh" bg="#F0EBE7">
      <Box w={isWide ? "55%" : "100%"} h="100vh" p="100px" pt="50px" display="flex" flexDirection="column" overflow="auto">
        <Box display="flex" flexDirection="row" alignItems="center" mb="6vh">
          <ChevronLeftIcon boxSize={12} color="#644E49" _hover={{ color: "#3D302D" }} onClick={onLeftClick} />
          <Text fontSize="3vh" fontWeight="bold" textColor="#644E49">
            project {curProject + 1} of {projects.length}
          </Text>
          <ChevronRightIcon boxSize={12} color="#644E49" _hover={{ color: "#3D302D" }} onClick={onRightClick} />
        </Box>
        <Box w="65%" mb="1vh">
          {projects[curProject].tags.map((tag, index) => (
            <Box
              key={index} bg="#E59E8E" boxShadow="base"
              borderRadius="15px" display="inline-flex" m="5px" p="2px 15px"
              textTransform="uppercase" fontWeight="bold" textColor="white"
              fontSize="13px"
            > {tag} </Box>
          ))}
        </Box>
        <Text
          w="70%" fontSize="5vh" fontWeight="black" textTransform="uppercase"
          textColor="#644E49" lineHeight={1.2} mb="4vh">
          {projects[curProject].projectName}
        </Text>

        {projects[curProject].descr.split("\n").map((line, index) => (
          <Text key={index} fontSize={isWide ? "1.5vh" : '14px'} fontWeight="medium" textColor="#644E49" mb={8}>
            {line}
          </Text>
        ))}
        <Text fontWeight='black' textColor="#644E49" fontSize={isWide ? "1.5vh" : '14px'}>&gt;&gt; PULL TO THE RIGHT TO RETURN</Text>
      </Box>
      {isWide && (
        <Box ref={scrollContainerRef} position="relative" w="45%"
            h="100vh" overflow="hidden" p="4"
        >
            <Box
            ref={scrollContentRef} position="absolute" top="0px" transition="transform 4s linear"
            >
            {projects[curProject].images.map((image, index) => (
                <Box key={index} mb="50px" boxShadow="base">
                <Image src={image} alt={`Carousel image ${index + 1}`} borderRadius="md" />
                </Box>
            ))}
            {projects[curProject].images.map((image, index) => (
                <Box key={index} mb="50px" boxShadow="base">
                <Image src={image} alt={`Carousel image ${index + 1}`} borderRadius="md" />
                </Box>
            ))}      
            </Box>
      </Box>)}
    </Box>
  );
}

export default BigProjects;
