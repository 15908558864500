import React, { useEffect, useState, useMemo } from 'react';
import {Box} from '@chakra-ui/react'

function Eye({width}){
    const outerDiameter = width;
    const outerRadius = outerDiameter / 2;
    const innerDiameter = width * 0.55;
    const innerRadius = innerDiameter / 2;
    const ellipseWidth = 200;
    const ellipseHeight = 150; 

    const [innerCirclePos, setInnerCirclePos] = useState({x: window.innerWidth / 2, y: window.innerHeight / 2});
    const outerCircleCenter = useMemo(() => ({x: window.innerWidth / 2, y: window.innerHeight / 2}), 
    []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const {clientX, clientY} = e;

            const dx = clientX - outerCircleCenter.x
            const dy = clientY - outerCircleCenter.y
            const distance = Math.sqrt(dx * dx + dy * dy)

            if (distance + innerRadius <= outerRadius){
                setInnerCirclePos({x: clientX, y: clientY});
            }else{
                const angle = Math.atan2(dy, dx);
                const constrainedX = outerCircleCenter.x + Math.cos(angle) * (outerRadius - innerRadius);
                const constrainedY = outerCircleCenter.y + Math.sin(angle) * (outerRadius - innerRadius);
                setInnerCirclePos({x: constrainedX, y: constrainedY});
            }
        }
        window.addEventListener('mousemove', handleMouseMove);

        return() => {
            window.removeEventListener('mousemove', handleMouseMove);
        }
    }, [outerCircleCenter, innerRadius, outerRadius])

    return (
    <Box postion='relative' top='0' left='0' w='100%' h='100%' display='flex' 
    justifyContent='center' alignItems='center'>
      {/* Outer circle (stationary) */}
      <Box position='relative' w={`${outerDiameter}px`} h={`${outerDiameter * 0.6}px`}
      borderRadius='50%' display='flex' justifyContent='center' alignItems='center' 
      overflow='hidden' bg='white' 
      >
        {/* Ellipse inside outer circle */}
        <Box position='absolute' w={`${ellipseWidth}px`} h={`${ellipseHeight}px`}
            bg='transparent' border='4px solid black' borderRadius='50%' clipPath='ellipse(100% 100% at 50% 50%)'
            display='flex' justifyContent='center' alignItems='center' overflow='hidden'
        >
          {/* Inner circle (follows mouse but constrained inside ellipse) */}
          <Box
            style={{
              position: 'absolute',
              left: `${innerCirclePos.x - outerCircleCenter.x + ellipseWidth / 2 - innerRadius}px`,
              top: `${innerCirclePos.y - outerCircleCenter.y + ellipseHeight / 2 - innerRadius}px`,
              width: `${innerDiameter}px`,
              height: `${innerDiameter}px`,
              borderRadius: '50%',
              backgroundColor: 'black',
            }}
          />
        </Box>
      </Box>
    </Box>

    );

}

function Bendy() {
    const [mousePos, setMousePos] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    const [eyeBlinkFunction, setEyeBlinkFunction] = useState({left: null, right:null})
    const [tailPath, setTailPath] = useState("")
    const circleRadius = window.innerWidth * 0.2;  // Circle radius
    const screenBottom = window.innerHeight;

    // Define the center and radius of the bounding circle
    const boundingCircleCenter = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const boundingCircleRadius = window.innerHeight * 0.4; // Adjust this radius as needed

    useEffect(() => {
      const handleMouseMove = (e) => {
          setMousePos({ x: e.clientX, y: e.clientY });
      
          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;
          const minX = screenWidth * 0.3; 
          const maxX = screenWidth * 0.7;
      
          const tailStartX = screenWidth / 2;
          const tailStartY = screenHeight;
      
          let mouseX = Math.min(Math.max(e.clientX, minX), maxX);
      
          const controlOffsetX = (mouseX - tailStartX) * 1.5;  
          const controlOffsetY = screenHeight * 0.2;           
      
          const control1X = tailStartX;     
          const control1Y = tailStartY;       
      
          const control2X = tailStartX - controlOffsetX * 0.5;
          const control2Y = tailStartY - controlOffsetY; 
      
          const tailEndX = window.innerWidth - mouseX;       
          const tailEndY = screenHeight - circleRadius * 3.5;  
      
          const pathData = `
            M${tailStartX} ${tailStartY}
            Q${control1X} ${control1Y}, ${control2X} ${control2Y}
            T${tailEndX} ${tailEndY}
          `;
      
          setTailPath(pathData);
      };
      window.addEventListener('mousemove', handleMouseMove);
  
      return () => {
          window.removeEventListener('mousemove', handleMouseMove);
      };
  }, [screenBottom]);
  

    const dx = mousePos.x - boundingCircleCenter.x;
    const dy = mousePos.y - boundingCircleCenter.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    
    let circleX = mousePos.x;
    let circleY = mousePos.y;

    if (distance > boundingCircleRadius - circleRadius) {
        const angle = Math.atan2(dy, dx);
        circleX = boundingCircleCenter.x + (boundingCircleRadius - circleRadius) * Math.cos(angle);
        circleY = boundingCircleCenter.y + (boundingCircleRadius - circleRadius) * Math.sin(angle);
    }

    const controlPointYBody = (circleY + screenBottom) / 2 - 50; // Adjust control point Y to be closer to the tangent
    
    const angleOffset = Math.PI / 30; 
    const tangentLeftX = circleX - circleRadius * Math.cos(angleOffset);
    const tangentRightX = circleX + circleRadius * Math.cos(angleOffset);
    const tangentY = circleY - circleRadius * Math.sin(angleOffset);

    const filledShapePath = `
      M ${window.innerWidth * 0.15},${screenBottom + 10} 
      Q ${tangentLeftX},${controlPointYBody} ${tangentLeftX},${tangentY}
      L ${tangentRightX},${tangentY}
      Q ${tangentRightX},${controlPointYBody} ${window.innerWidth * 0.85},${screenBottom + 10}
      Z
    `;

    const nose = "M23.8097 0.500015C65.8097 0.500015 41.3097 18.5 23.8097 27.5C6.3097 18.5 -18.1903 0.500015 23.8097 0.500015Z"

    
    const handleBlink = () => {
        if(eyeBlinkFunction.left && eyeBlinkFunction.right){
            eyeBlinkFunction.left()
            eyeBlinkFunction.right()
        }
    }

    return (
        <Box position='relative' w='100vw' h='100vh' onClick={handleBlink} bg='papayawhip'>
        <svg style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', pointerEvents: 'none' }}>
            <path d={tailPath} fill='none' stroke='#8a9fa8' strokeWidth='75' strokeLinecap='round'/>
            <path d={filledShapePath} fill="#A5BCC7"/>
            <circle
                cx={circleX}
                cy={circleY}
                r={circleRadius}
                fill="#A5BCC7"
            />
            {/* Ears */}
            <polygon 
                    points={`${circleX - circleRadius * 1.5 + window.innerWidth * 0.1},${circleY - window.innerWidth * 0.25} ${circleX},${circleY - circleRadius * 0.6} ${circleX - circleRadius + 15},${circleY + circleRadius * 0.3}`}
                    fill="#A5BCC7"
            />
            <polygon 
                points={`${circleX + circleRadius * 1.5 - window.innerWidth * 0.1},${circleY - window.innerWidth * 0.25} ${circleX},${circleY - circleRadius * 0.6} ${circleX + circleRadius - 25},${circleY + circleRadius * 0.3}`}
                fill="#A5BCC7"
            />
        </svg>
        <Box position='absolute' display='flex' w={circleRadius * 2} top={circleY - (circleRadius * 0.4)} left={circleX - circleRadius} justifyContent='space-between'>
            <Eye width={circleRadius * 0.45} handleBlink={(blink) => setEyeBlinkFunction((prev) => ({ ...prev, left: blink }))}></Eye>
            <Eye width={circleRadius * 0.45} handleBlink={(blink) => setEyeBlinkFunction((prev) => ({ ...prev, right: blink }))}></Eye>
        </Box>
        <Box position='absolute' top={circleY} left={circleX - (circleRadius * 0.15)} 
        w={`${circleRadius * 0.7}px`} height='auto'>
            {/*Nose */}
            <svg w='100%' h='100%' viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
                <path d={nose} fill='#E8C5D5'/>
                <line x1="24" y1="10" x2="24" y2="70" stroke="#E8C5D5" strokeWidth="4" />
            </svg>
        </Box>
        </Box>
    );

}

export default Bendy;
